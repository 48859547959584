'use strict';

(function($){
  var app = {

    init: function() {
      this.megaMenu();
      this.search();
      this.stickyMenu();
      this.counter();
      this.cookiesAccept();
      this.cookiesConfig();
      this.lazyframe();
      this.btnAnchor();
      this.videoSlider();
			this.slickSlider();
      this.menuCorporate();
      this.menuComunication();
      this.menuReferences();
      this.prepareLateralMenu();
      this.carouselFullscreen();
      this.sectorsContacts();
      this.customFile();
      return false;
    },
    megaMenu: function () {
     $('.toggle-menu').click(function(){
      $('.exo-menu').toggleClass('display');
     });
    },
    search: function () {
      $("span.search-button").click(function(){
        $(".search-wrapper").css("bottom", "0px");
        $("button.close-search").css("display", "block");
      });
      $("button.close-search").click(function(){
        $(".search-wrapper").css("bottom", "-2000px");
        $("button.close-search").css("display", "none");
      });
    },
    stickyMenu: function () {
       if(typeof $('.sidebar-nav').html() !== 'undefined'){
         // grab the initial top offset of the navigation
         var stickyNavTop = $('.sidebar-nav').offset().top;

         // our function that decides weather the navigation bar should have "fixed" css position or not.
         var stickyNav = function(){
           var scrollTop = $(window).scrollTop(); // our current vertical position from the top

           // if we've scrolled more than the navigation, change its position to fixed to stick to top,
           // otherwise change it back to relative
           if (scrollTop > stickyNavTop) {
             $('.sidebar-nav').addClass('sticky');
           } else {
             $('.sidebar-nav').removeClass('sticky');
           }
         };
         stickyNav();
         // and run it again every time you scroll
         $(window).scroll(function() {
           stickyNav();
         });
       }
    },
    cookiesAccept: function () {
      var msgCookies = $('#cookies-msg');
      var btnAccept = $('.btn-cookies-accept');
      btnAccept.click(function (e){
        e.preventDefault();
        $.cookie(keyAnalyticCookies, '1', { expires: 7, path: '/' });
        $.cookie(keyMarketingCookies, '1', { expires: 7, path: '/' });
        $.cookie(keyRrssCookies, '1', { expires: 7, path: '/' });
        msgCookies.slideUp();
      })
    },
    counter: function (){
        $('.counter').each(function () {
          $(this).prop('Counter',0).animate({
              Counter: $(this).text()
          }, {
              duration: 4000,
              easing: 'swing',
              step: function (now) {
                  $(this).text(Math.ceil(now));
              }
          });
        });
      },
    cookiesConfig: function (){
      var configContainer = $('.cookies-configuration-block');
      var msgCookies = $('#cookies-msg');

      if(typeof configContainer.html() !== 'undefined')
      {
        var checkAll = configContainer.find('input#cookies-todas');
        var checkAnalytics = configContainer.find('input#cookies-analiticas');
        var checkMarketing = configContainer.find('input#cookies-marketing');
        var checkRrss = configContainer.find('input#cookies-rrss');
        var buttonSave = configContainer.find('.btn-save-config');
        var msgSuccess = configContainer.find('#text-success');
        var msgError = configContainer.find('#text-error');

        checkAll.click(function ()
        {
          if($(this).prop('checked'))
          {
            checkAnalytics.prop('checked',true);
            checkMarketing.prop('checked',true);
            checkRrss.prop('checked',true);
          }
          else
          {
            checkAnalytics.prop('checked',false);
            checkMarketing.prop('checked',false);
            checkRrss.prop('checked',false);
          }
        })
        buttonSave.click(function ()
        {
          msgSuccess.addClass('d-none');
          msgError.addClass('d-none');
          $.cookie(keyAnalyticCookies, ((checkAnalytics.prop('checked')) ? '1' : '0'), { expires: 7, path: '/' });
          $.cookie(keyMarketingCookies, ((checkMarketing.prop('checked')) ? '1' : '0'), { expires: 7, path: '/' });
          $.cookie(keyRrssCookies, ((checkRrss.prop('checked')) ? '1' : '0'), { expires: 7, path: '/' });
          if(typeof msgCookies.html() !== 'undefined') msgCookies.hide();
          msgSuccess.removeClass('d-none');
        })
      }
    },
    lazyframe: function () {
      lazyframe('.lazyframe', {
        apikey: 'AIzaSyBG22hzTpV5lxNFeYgNDuv1SSD_NBEAu9k', // cambiar esta API por la del proyecto https://github.com/vb/lazyframe#apikey
        debounce: 250,

        onAppend: (function (lazyframe) {
          return console.log(lazyframe.parentNode.classList.add("loaded"))
        })
      });
    },
    controlButtonAnchor : function (caller,target) {
      var position = $(window).scrollTop();
      var height = $(window).height();
      var bottom = position + height;
      var targetTop = target.position().top;

      if(bottom >= targetTop) caller.hide();
      else caller.show();

    },
    btnAnchor : function () {
      $(document).ready(function ()
      {
        var btnAnchor = $('.btn-anchor');
        if(typeof btnAnchor.html() !== 'undefined') {
          var target = $(btnAnchor.attr('href'));
          var sectionTarget = target.closest('.form-bottom');
          btnAnchor.click(function (e){
            e.preventDefault();
            sectionTarget.addClass('modal-type');
            $('body').addClass('blocked');
          })
          sectionTarget.find('.close').click(function (e){
            e.preventDefault();
            sectionTarget.removeClass('modal-type');
            $('body').removeClass('blocked');
          })
          app.controlButtonAnchor(btnAnchor,sectionTarget);
          $(window).scroll(function (){
              app.controlButtonAnchor(btnAnchor,sectionTarget);
          })
          $(window).resize(function (){
              app.controlButtonAnchor(btnAnchor,sectionTarget);
          })
        }
      })
    },
    videoSlider : function ()
    {
      var carousel = $('.video-carousel');
      if(typeof carousel.html() !== 'undefined')
      {
        carousel.on('ready.flickity',function (){
          setTimeout(function ()
          {
            if(typeof carousel.find('.is-selected').find('iframe').html() !== 'undefined')
            {
              carousel.find('.is-selected').find('iframe')[0].contentWindow.postMessage('{"event":"command","func":"' + 'playVideo' + '","args":""}', '*');
            }
          },1000)
        })
        var $flickity = carousel.flickity({ "lazyLoad": 1, "pageDots": false, "adaptiveHeight": true });
        $flickity.on('selected.flickity',function (e,i){
          carousel.find('iframe').each(function (){
            var obj = $(this);
            if($(this).parent().parent().hasClass('is-selected'))
            {
              obj[0].contentWindow.postMessage('{"event":"command","func":"' + 'playVideo' + '","args":""}', '*');
            }
            else
            {
              obj[0].contentWindow.postMessage('{"event":"command","func":"' + 'pauseVideo' + '","args":""}', '*');
            }
          })
        })
      }
    },
		slickSlider: function(){
			$(".slick").slick()
		},
    menuCorporate : function ()
    {
      var navCorporative = $('.nav-corporative');
      var subNavCorporative = $('.nav-corporative').children('ul');
      if(typeof subNavCorporative.html() !== 'undefined')
      {
        //Colocamos la capa submenu
        var hNav = navCorporative.outerHeight();
        subNavCorporative.css({top : hNav + 'px'})

        //Mostramos ocultamos segun corresponda
        navCorporative.mouseenter(function ()
        {
          subNavCorporative.stop(true,false).slideDown(150);
        }).mouseleave(function (){
          subNavCorporative.stop(true,false).slideUp(150);
        })
      }
    },
    menuComunication : function ()
    {
      var navComunication = $('.nav-comunication');
      var subNavComunication = $('.nav-comunication').children('ul');
      if(typeof subNavComunication.html() !== 'undefined')
      {
        //Colocamos la capa submenu
        var hNav = navComunication.outerHeight();
        subNavComunication.css({top : hNav + 'px'})

        //Mostramos ocultamos segun corresponda
        navComunication.mouseenter(function ()
        {
          subNavComunication.stop(true,false).slideDown(150);
        }).mouseleave(function (){
          subNavComunication.stop(true,false).slideUp(150);
        })
      }
    },
    cleanActivesMenuReferences : function (links){
      links.each(function (){
        $(this).parent().removeClass('active');
      })
    },
    changeActiveMenuReferences : function (menu,caller)
    {
      var menuLinks = menu.find('a');
      if(caller === undefined)
      {
        if(window.location.hash !== ''){
          var anchor = window.location.hash;
          this.cleanActivesMenuReferences(menuLinks);
          menu.find('a[href$="' + anchor + '"]').parent().addClass('active');
        }
      }
      else {
        this.cleanActivesMenuReferences(menuLinks);
        caller.parent().addClass('active');
      }
    },
    menuReferences : function () {
      var menu = $('#aside-references');
      if(typeof menu.html() !== 'undefined') {
        this.changeActiveMenuReferences(menu);
        menu.find('a').on('click',function (){
          app.changeActiveMenuReferences(menu,$(this));
        })
      }
    },
    controleOversize : function(sidebar,list){
      var sidebarHeight = sidebar.height();
      var listHeight = list.height();

      if(listHeight > sidebarHeight) sidebar.addClass('oversized');
      else sidebar.removeClass('oversized');
    },
    prepareLateralMenu : function () {
      var btnSidebar = $('#sidebar-btn');
      var sidebar = $('#sidebar');
      var list = $('#sidebar > ul');
      this.controleOversize(sidebar,list);
      btnSidebar.click(function(){
        $('#sidebar').toggleClass('visible');
      });
      $(window).resize(function () {
        app.controleOversize(sidebar,list);
      })
    },
    carouselFullscreen : function () {
      var options =  { groupCells: false, fullscreen: true, lazyLoad: 1, cellAlign: "left", contain: true };
      $('.carousel-fullscreen').each(function() {
        var carousel = $(this).flickity(options);
        carousel.find('img').click(function () {
          var index = $(this).closest('.carousel-cell').index();
          carousel.flickity('select',index);
          carousel.flickity('viewFullscreen');
        })
      })

    },
    sectorsContacts : function () {
      var commercialsContainer = $('.commercials');
      var btnsDeploy = commercialsContainer.find('.btn-deploy');
      var commercialsItems = commercialsContainer.find('.commercials-sector');
      btnsDeploy.click(function (e) {
        e.preventDefault();
        if(!$(this).hasClass('active')) {
          btnsDeploy.removeClass('active');
          $(this).addClass('active');
          commercialsItems.removeClass('active');
          $($(this).attr('href')).addClass('active');
        }
      })
    },
    customFile : function () {
      $('.custom-file-input').change(function (){
        if($(this).val() !== '') {
          let value = $(this).val();
          if(value.indexOf('fakepath') !== -1) {
            value = value.split('\\').pop();
          }
          $(`label[for="${$(this).attr('id')}"]`).html(value);
        }
      })
    }
  };
  $(function(){
    $(document).ready(function() {
      "use strict";
      $('.menu > ul > li:has( > ul)').addClass('menu-dropdown-icon');
      $('.menu > ul > li > ul:not(:has(ul))').addClass('normal-sub');
      $(".menu > ul").before("<a href=\"#\" class=\"menu-mobile\">&nbsp;</a>");
      if ($(window).width() > 1199) {
        $(".menu > ul > li").hover(function(e) {
          var visible = $(this).children("ul").is(':visible');
          if(!visible)
          {
            var thisChildrenHtml = $(this).children("ul").html();
            $('.normal-sub').each(function ()
            {
              if($(this).html() !== thisChildrenHtml) $(this).slideUp(150);
            })
            $(this).children("ul").stop(true,false).slideDown(150);
          }
          else
          {
            $(this).children("ul").stop(true,false).slideUp(150);
          }
        });
      }
      if ($(window).width() <= 1199) {
        $(".menu > ul > li").click(function() {
            $(this).children("ul").fadeToggle(150);
        });
      }
      $(".menu-mobile").click(function(e) {
				$('body').toggleClass('menu--opened')
        $(".menu > ul").toggleClass('show-on-mobile');
        e.preventDefault();
      });
    });
    $(window).resize(function() {
      $(".menu > ul > li").children("ul").hide();
			$('body').removeClass('menu--opened')
      $(".menu > ul").removeClass('show-on-mobile');
    });

    $(".show").flickity({
      wrapAround: true,
      //pageDots: false
    });

    function nextPrev(slider, btn, wrapped) {
      $(btn).on('click', function(e) {
        e.preventDefault();

        var dir = $(this).attr('data-direction');
        slider.flickity(dir, wrapped, false);
      })
    }
    var $navBtn = $('.carousel-nav__button');
    function updateNav(el) {
      $navBtn.removeClass('carousel-nav__button--is-active');
      el.addClass('carousel-nav__button--is-active');
    }
    function disableInactiveBtns() {
      var flkty = $carousel.data('flickity');

      if (typeof flkty !== 'undefined' && flkty.selectedIndex === 0) {
        $('.carousel-btn--prev').prop('disabled', true);
      } else {
        $('.carousel-btn--prev').prop('disabled', false);
      }

      if (typeof flkty !== 'undefined' && flkty.selectedIndex === flkty.cells.length - 1) {
        $('.carousel-btn--next').prop('disabled', true);
      } else {
        $('.carousel-btn--next').prop('disabled', false);
      }
    }
    if(typeof $('.historia-carousel').html() !== 'undefined')
    {
      //Da errores y parece que no aporta
      // create default carousel options
      var flkOptions = {
        cellAlign: 'left',
        contain: true,
        prevNextButtons: false,
        dragThreshold: 10,
        wrapAround: true,
      }
      // init carousel
      var $carousel = $('.historia-carousel .carousel').flickity(flkOptions);
      // Custom next/prev arrows
      var $btnOne = $('.historia-carousel .carousel-btn')
      nextPrev($carousel, $btnOne, false);
      $navBtn.on('click', function(e) {
        e.preventDefault();
        updateNav($(this));
        var active = $('.carousel-nav .carousel-nav__button--is-active').index();
        $carousel.flickity( 'select', active)
      })
      $carousel.on('select.flickity', function(){
        var index = $carousel.data('flickity').selectedIndex;
        var active = $('.carousel-nav .carousel-nav__button').eq(index);
        updateNav(active);
        disableInactiveBtns();
      })
      disableInactiveBtns()
    }

    app.init()
  });
})(jQuery);